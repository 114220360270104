import PageLayout from "../../../pages/home/PageLayout";
import HomePage from "../../../pages/home/HomePage";
import AddCategoryPage from "../../../pages/category/addCategoryPage";
import ListCategoryPage from "../../../pages/category/listCategoryPage";
import AddBrandPage from "../../../pages/brand/addBrandPage";
import ListBrandPage from "../../../pages/brand/listBrandPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GrainIcon from "@mui/icons-material/Grain";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import DiscountIcon from "@mui/icons-material/Discount";
import CategoryIcon from "@mui/icons-material/Category";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import ListSubCategoryPage from "../../../pages/subCategory/listSubCategoryPage";
import AddSubCategoryPage from "../../../pages/subCategory/addSubCategoryPage";
import AddProductPage from "../../../pages/product/addProductPage";
import ListProductPage from "../../../pages/product/listProductPage";
import AddCouponPage from "../../../pages/coupon/addCouponPage";
import ListCouponPage from "../../../pages/coupon/listCouponPage";
import SalesSumamryReportPage from "../../../pages/report/salesReportPage";
import CancelledReportPage from "../../../pages/report/cancelledReportPage";
import OrderReportPage from "../../../pages/report/orderReportPage";
import RunningOrderReportPage from "../../../pages/report/runningOrderReportPage";
import ListCustomerPage from "../../../pages/customer/listCustomerPage";
import UpdateCustomerPage from "../../../pages/customer/updateCustomerPage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UpdateAdminPage from "../../../pages/admin/updateAdminPage";
import ListAdminPage from "../../../pages/admin/listAdminPage";
import AllOrderPage from "../../../pages/order/all-order";
import OrderDtailsAndUpdatePage from "../../../pages/order/orderDtailsAndUpdate";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import MainSlider from "../../settings/mainSlider";
import MainSliderForMobile from "../../settings/mainSliderForMobile";

import ShippingAndOtherCost from "../../settings/shippingAndOtherCost";
import SocialLink from "../../settings/addSocialLink";

import ReturnedReportPage from "../../../pages/report/returnedReportPage";
import AddSubSubCategoryPage from "../../../pages/subSubCategory/addSubSubCategoryPage";
import ListSubSubCategoryPage from "../../../pages/subSubCategory/listSubSubCategoryPage";
import ListShippingCostAndOtherCost from "../../settings/listShippingAndOtherCost";
import ListSocialLinks from "../../settings/listSocialLink";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";

import AddPage from "../../../pages/privacyPolicy/aboutUs/addPage";
import ListPage from "../../../pages/privacyPolicy/aboutUs/listPage";
import AddPageReturnAndRefund from "../../../pages/privacyPolicy/returnAndRefund/addPage";
import ListPageReturnAndRefund from "../../../pages/privacyPolicy/returnAndRefund/listPage";
import AddPageTermAndConditions from "../../../pages/privacyPolicy/termAndConditions/addPage";
import ListPageTermAndConditions from "../../../pages/privacyPolicy/termAndConditions/listPage";
import AddPageTermAndServices from "../../../pages/privacyPolicy/termAndServices/addPage";
import ListPageTermAndServices from "../../../pages/privacyPolicy/termAndServices/listPage";
import AddPagePrivacyPolicy from "../../../pages/privacyPolicy/privacyPolicy/addPage";
import ListPagePrivacyPolicy from "../../../pages/privacyPolicy/privacyPolicy/listPage";
import AddPageRefundPolicy from "../../../pages/privacyPolicy/refundPolicy/addPage";
import ListPageRefundPolicy from "../../../pages/privacyPolicy/refundPolicy/listPage";
import AddMultipleCurrencyPage from "../../../pages/multipleCurrency/addMultipleCurrencyPage";
import ListMultipleCurrencyPage from "../../../pages/multipleCurrency/listMultipleCurrencyPage";
import BrandB1G1Page from "../../../pages/setOffers/brandB1G1";
import BrandB2G1Page from "../../../pages/setOffers/brandB2G1";
import CategoryB1G1Page from "../../../pages/setOffers/categoryB1G1";
import CategoryB2G1Page from "../../../pages/setOffers/categoryB2G1";
import PopupNotificationImg from "../../settings/popupNotificationImg";

const appRoutes = [
  // dashboard
  {
    path: "/",
    element: <HomePage />,
    state: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardIcon />,
    },
  },

  // Order
  {
    path: "/order",
    element: <PageLayout />,
    state: "order",
    sidebarProps: {
      displayText: "Order",
      icon: <ShoppingBagIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "order.index",
      },
      {
        path: "/order/all-order",
        element: <AllOrderPage />,
        state: "order.all-order",
        sidebarProps: {
          displayText: "Orders",
        },
      },
      {
        path: "/order/details-and-update-order",
        element: <OrderDtailsAndUpdatePage />,
        state: "order.details-and-update-order",
        // sidebarProps: {
        //   displayText: "Orders",
        // },
      },
    ],
  },

  // category
  {
    path: "/category",
    element: <PageLayout />,
    state: "category",
    sidebarProps: {
      displayText: "Category",
      icon: <CategoryIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "category.index",
      },
      {
        path: "/category/add-category",
        element: <AddCategoryPage />,
        state: "category.add-category",
        sidebarProps: {
          displayText: "Add Category",
        },
      },
      {
        path: "/category/list-category",
        element: <ListCategoryPage />,
        state: "category.list-category",
        sidebarProps: {
          displayText: "List Category",
        },
      },
    ],
  },

  // sub category
  {
    path: "/subCategory",
    element: <PageLayout />,
    state: "SubCategory",
    sidebarProps: {
      displayText: "Sub-category",
      icon: <AccountTreeIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "SubCategory.index",
      },
      {
        path: "/subCategory/add-subcategory",
        element: <AddSubCategoryPage />,
        state: "SubCategory.add-subCategory",
        sidebarProps: {
          displayText: "Add Sub-category",
        },
      },
      {
        path: "/subCategory/list-subCategory",
        element: <ListSubCategoryPage />,
        state: "SubCategory.list-subCategory",
        sidebarProps: {
          displayText: "List Sub-category",
        },
      },
    ],
  },
  // sub sub category
  {
    path: "/sub-sub-categories",
    element: <PageLayout />,
    state: "sub-sub-categories",
    sidebarProps: {
      displayText: "Sub sub-category",
      icon: <GrainIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "sub-sub-categories.index",
      },
      {
        path: "/sub-sub-categories/add-sub-sub-categories",
        element: <AddSubSubCategoryPage />,
        state: "sub-sub-categories.add-sub-sub-categories",
        sidebarProps: {
          displayText: "Add Sub sub-category",
        },
      },
      {
        path: "/sub-sub-categories/list-sub-sub-categories",
        element: <ListSubSubCategoryPage />,
        state: "sub-sub-categories.list-sub-sub-categories",
        sidebarProps: {
          displayText: "List Sub sub-category",
        },
      },
    ],
  },

  // brand
  {
    path: "/brand",
    element: <PageLayout />,
    state: "brand",
    sidebarProps: {
      displayText: "Brand",
      icon: <BrandingWatermarkIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "brand.index",
      },
      {
        path: "/brand/add-brand",
        element: <AddBrandPage />,
        state: "brand.add-brand",
        sidebarProps: {
          displayText: "Add brand",
        },
      },
      {
        path: "/brand/list-brand",
        element: <ListBrandPage />,
        state: "brand.list-brand",
        sidebarProps: {
          displayText: "List brand",
        },
      },
    ],
  },

  // products
  {
    path: "/products",
    element: <PageLayout />,
    state: "Products",
    sidebarProps: {
      displayText: "Products",
      icon: <HomeRepairServiceIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "Products.index",
      },
      {
        path: "/products/add-products",
        element: <AddProductPage />,
        state: "Products.add-product",
        sidebarProps: {
          displayText: "Add Product",
        },
      },
      {
        path: "/products/list-products",
        element: <ListProductPage />,
        state: "Products.list-product",
        sidebarProps: {
          displayText: "List Product",
        },
      },
    ],
  },

  // set offers (B1G1 & B2G1)

  {
    path: "/set-offers",
    element: <PageLayout />,
    state: "SetOffers",
    sidebarProps: {
      displayText: "Set Offers (B1G1 & B2G1)",
      icon: <StrikethroughSIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "SetOffers.index",
      },
      {
        path: "/set-offers/brnd-wise-b1g1",
        element: <BrandB1G1Page />,
        state: "set-offers.brnd-wise-b1g1",
        sidebarProps: {
          displayText: "Brand Wise B1G1",
        },
      },
      {
        path: "/set-offers/brnd-wise-b2g1",
        element: <BrandB2G1Page />,
        state: "set-offers.brnd-wise-b2g1",
        sidebarProps: {
          displayText: "Brand Wise B2G1",
        },
      },
      {
        path: "/set-offers/class-wise-b1g1",
        element: <CategoryB1G1Page />,
        state: "set-offers.class-wise-b1g1",
        sidebarProps: {
          displayText: "Category Wise B1G1",
        },
      },
      {
        path: "/set-offers/class-wise-b2g1",
        element: <CategoryB2G1Page />,
        state: "set-offers.class-wise-b2g1",
        sidebarProps: {
          displayText: "Category Wise B2G1",
        },
      },
    ],
  },

  // coupon
  {
    path: "/coupon",
    element: <PageLayout />,
    state: "coupon",
    sidebarProps: {
      displayText: "Coupon",
      icon: <DiscountIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "coupon.index",
      },
      {
        path: "/coupon/add-coupon",
        element: <AddCouponPage />,
        state: "coupon.add-coupon",
        sidebarProps: {
          displayText: "Add Coupon",
        },
      },
      {
        path: "/coupon/list-coupon",
        element: <ListCouponPage />,
        state: "coupon.list-coupon",
        sidebarProps: {
          displayText: "List Coupon",
        },
      },
    ],
  },

  // Multiple currency
  {
    path: "/multiple-currency",
    element: <PageLayout />,
    state: "multiple-currency",
    sidebarProps: {
      displayText: "Multiple Currency",
      icon: <CurrencyExchangeIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "multiple-currency.index",
      },
      {
        path: "/multiple-currency/add-multiple-currency",
        element: <AddMultipleCurrencyPage />,
        state: "multiple-currency.add-multiple-currency",
        sidebarProps: {
          displayText: "Add Currency",
        },
      },
      {
        path: "/multiple-currency/list-multiple-currency",
        element: <ListMultipleCurrencyPage />,
        state: "multiple-currency.list-multiple-currency",
        sidebarProps: {
          displayText: "List Currency",
        },
      },
    ],
  },

  // customer
  {
    path: "/customer",
    element: <PageLayout />,
    state: "Customer",
    sidebarProps: {
      displayText: "Customers",
      icon: <PeopleOutlineIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "customer.index",
      },
      {
        path: "/customer/update-customer",
        element: <UpdateCustomerPage />,
        state: "customer.update-customer",
        // sidebarProps: {
        //   displayText: "Update Customer",
        // },
      },
      {
        path: "/customer/list-customer",
        element: <ListCustomerPage />,
        state: "customer.list-customer",
        sidebarProps: {
          displayText: "Customer List",
        },
      },
    ],
  },

  // admin
  {
    path: "/admins",
    element: <PageLayout />,
    state: "Admin",
    sidebarProps: {
      displayText: "Admins",
      icon: <AdminPanelSettingsIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "admins.index",
      },
      {
        path: "/admins/update-admin",
        element: <UpdateAdminPage />,
        state: "admins.update-admin",
        // sidebarProps: {
        //   displayText: "Update Customer",
        // },
      },
      {
        path: "/admins/list-admin",
        element: <ListAdminPage />,
        state: "admins.list-admin",
        sidebarProps: {
          displayText: "Admin List",
        },
      },
    ],
  },

  // report
  {
    path: "/report",
    element: <PageLayout />,
    state: "report",
    sidebarProps: {
      displayText: "Report",
      icon: <StackedBarChartIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "report.index",
      },
      {
        path: "/report/sales-report",
        element: <SalesSumamryReportPage />,
        state: "report.sales-report",
        sidebarProps: {
          displayText: "Sales Report",
        },
      },
      {
        path: "/report/cancelled-report",
        element: <CancelledReportPage />,
        state: "report.cancelled-report",
        sidebarProps: {
          displayText: "Cancelled Report",
        },
      },
      {
        path: "/report/returned-report",
        element: <ReturnedReportPage />,
        state: "report.returned-report",
        sidebarProps: {
          displayText: "Retured Report",
        },
      },
      {
        path: "/report/order-report",
        element: <OrderReportPage />,
        state: "report.order-report",
        sidebarProps: {
          displayText: "TotalOrder Report",
        },
      },
      {
        path: "/report/running-order-report",
        element: <RunningOrderReportPage />,
        state: "report.running-order-report",
        sidebarProps: {
          displayText: "Running Order Report",
        },
      },
    ],
  },

  // settings
  {
    path: "/settings",
    element: <PageLayout />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsApplicationsIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "settings.index",
      },

      {
        path: "/settings/main-slider",
        element: <MainSlider />,
        state: "settings.main-slider",
        sidebarProps: {
          displayText: "Main Slider",
        },
      },
      {
        path: "/settings/main-slider-for-mobile",
        element: <MainSliderForMobile />,
        state: "settings.main-slider-for-mobile",
        sidebarProps: {
          displayText: "Main Slider For Mobile",
        },
      },
      {
        path: "/settings/popup-notification-img",
        element: <PopupNotificationImg />,
        state: "settings.popup-notification-img",
        sidebarProps: {
          displayText: "Pop Up Notification",
        },
      },

      {
        path: "/settings/shipping-and-other-cost",
        element: <ShippingAndOtherCost />,
        state: "settings.shipping-and-other-cost",
        sidebarProps: {
          displayText: "Add Shipping & Other Cost",
        },
      },
      {
        path: "/settings/list-shipping-and-other-cost",
        element: <ListShippingCostAndOtherCost />,
        state: "settings.list-shipping-and-other-cost",
        sidebarProps: {
          displayText: "List Shipping & Other Cost",
        },
      },
      {
        path: "/settings/add-social-link",
        element: <SocialLink />,
        state: "settings.add-social-link",
        sidebarProps: {
          displayText: "Add Social Link",
        },
      },
      {
        path: "/settings/list-social-link",
        element: <ListSocialLinks />,
        state: "settings.list-social-link",
        sidebarProps: {
          displayText: "List Social Link",
        },
      },
    ],
  },

  // All pages
  {
    path: "/privacy-policy",
    element: <PageLayout />,
    state: "privacy-policy",
    sidebarProps: {
      displayText: "All Pages",
      icon: <NoteAltIcon />,
    },
    child: [
      {
        index: true,
        element: <PageLayout />,
        state: "privacy-policy.index",
      },
      // about us
      {
        path: "/privacy-policy/add-about-us-privacy-policy",
        element: <AddPage />,
        state: "privacy-policy.add-about-us-privacy-policy",
        sidebarProps: {
          displayText: "Add About Us ",
        },
      },
      {
        path: "/privacy-policy/list-about-us-privacy-policy",
        element: <ListPage />,
        state: "privacy-policy.list-about-us-privacy-policy",
        sidebarProps: {
          displayText: "List About Us ",
        },
      },
      // return and refund
      {
        path: "/privacy-policy/add-return-and-refund-privacy-policy",
        element: <AddPageReturnAndRefund />,
        state: "privacy-policy.add-return-and-refund-privacy-policy",
        sidebarProps: {
          displayText: "Add Return & Refund ",
        },
      },
      {
        path: "/privacy-policy/list-return-and-refund-privacy-policy",
        element: <ListPageReturnAndRefund />,
        state: "privacy-policy.list-return-and-refund-privacy-policy",
        sidebarProps: {
          displayText: "List Return & Refund ",
        },
      },
      // Term & Conditions
      {
        path: "/privacy-policy/add-term-and-conditions-privacy-policy",
        element: <AddPageTermAndConditions />,
        state: "privacy-policy.add-term-and-conditions-privacy-policy",
        sidebarProps: {
          displayText: "Add Term & Conditions ",
        },
      },
      {
        path: "/privacy-policy/list-term-and-conditions-privacy-policy",
        element: <ListPageTermAndConditions />,
        state: "privacy-policy.list-term-and-conditions-privacy-policy",
        sidebarProps: {
          displayText: "List Term & Conditions ",
        },
      },
      // Term & Services
      {
        path: "/privacy-policy/add-term-and-services-privacy-policy",
        element: <AddPageTermAndServices />,
        state: "privacy-policy.add-term-and-services-privacy-policy",
        sidebarProps: {
          displayText: "Add Term & Services ",
        },
      },
      {
        path: "/privacy-policy/list-term-and-services-privacy-policy",
        element: <ListPageTermAndServices />,
        state: "privacy-policy.list-term-and-services-privacy-policy",
        sidebarProps: {
          displayText: "List Term & Services ",
        },
      },
      // Privacy Policy
      {
        path: "/privacy-policy/add-privacy-policy",
        element: <AddPagePrivacyPolicy />,
        state: "privacy-policy.add-privacy-policy",
        sidebarProps: {
          displayText: "Add Privacy Policy ",
        },
      },
      {
        path: "/privacy-policy/list-privacy-policy",
        element: <ListPagePrivacyPolicy />,
        state: "privacy-policy.list-privacy-policy",
        sidebarProps: {
          displayText: "List Privacy Policy ",
        },
      },
      // Refund Policy
      {
        path: "/privacy-policy/add-refund-policy",
        element: <AddPageRefundPolicy />,
        state: "privacy-policy.add-refund-policy",
        sidebarProps: {
          displayText: "Add Refund Policy ",
        },
      },
      {
        path: "/privacy-policy/list-refund-policy",
        element: <ListPageRefundPolicy />,
        state: "privacy-policy.list-refund-policy",
        sidebarProps: {
          displayText: "List Refund Policy ",
        },
      },
    ],
  },
];

export default appRoutes;
